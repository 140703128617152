













































































import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'
import firebase from 'firebase'
@Component({
    components: {

    },
})
export default class Home extends Vue {
    private form: any = {}

    async created() {
        let loader = await this.$loading.show()
        try {
            await Auth.run(); 
          await this.callback()
        } catch (Error) {
            await loader.hide()
        }

        await loader.hide()

    }

    async microsoft() {
        await Auth.reToken();
        await Auth.loginMicrosoft365();
    }

    async facebook() {
        await Auth.reToken();
        await Auth.loginFacebook();
    }

    async google() {
        await Auth.reToken();
        await Auth.loginGoogle();
    }

    async callback() {
        let logined: any = await Auth.callback();
        let form: any | null = null
        if (logined.type) {
            console.log(logined.user)
            await Auth.reToken();
            form = await Auth.genForm(logined.type, logined.user)
            if ((await Auth.checkUser(form.username)).user) {
                let key = await Core.postHttp(`/rest-auth/login/`, form)
                await this.keyCall(key.key)
            } else {
                let user = await Core.postHttp(`/api/ita/v1/register/`, form)
                if (user.id) {
                    let key = await Core.postHttp(`/rest-auth/login/`, form)
                    await this.keyCall(key.key)
                }
            }
        }
    }

    async getLogin() {
        await Auth.reToken();
        let key = await Core.postHttp(`/rest-auth/login/`, this.form)
        if (key.key) {
            await this.keyCall(key.key)
        }
    }

    async keyCall(Key: string) {
        await Auth.storeToken(Key)
        await Auth.storeTokenToStorage(Key)
        await User.loadUser()
        await this.$router.replace(User.routeUser)
    }

}
